<template>
  <div>
    <b-card :class="$store.state.appConfig.layout.skin === 'dark' ?'p-0 filter-card rounded-lg dark-card' : 'p-0 filter-card rounded-lg'">
      <b-row>
        <b-col cols="12">
          <h3 :class="$store.state.appConfig.layout.skin === 'dark' ? 'ml-1 mb-2 text-light' : 'ml-1 mb-2'">Filters</h3>
        </b-col>
        <b-col cols="4">
          <v-select
            v-model="filter.status"
            :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
            :options="status_data"
            class="w-100 mb-0"
            :reduce="(val) => val.id"
            placeholder="School Status"
            :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
          >
            <template v-slot:option="option">
              {{ $i18n.locale == "ar" ? option.name_local : option.name }}
            </template>
            <template #selected-option="{ name, name_local }">
              <div style="display: flex; align-items: baseline">
                <strong v-if="$i18n.locale == 'ar'">{{ name_local }} </strong>
                <strong v-else>{{ name }} </strong>
              </div>
            </template>
            <template #no-options>
              {{ $t("noMatching") }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="4">
          <b-form-input 
            v-model="filter.keyword"
            placeholder="School Name"
          />
          
        </b-col>
        
      </b-row>
    </b-card>
    <b-card class="w-100 d-flex rounded-lg">
      <div class="d-flex align-item-center">
        <h3 class="ml-1">Schools</h3>

         <b-button
         v-if="hasPermission('Add School')"
          class="btn rounded-pill ml-auto mb-1"
          variant="primary"
          @click="addSchool()"
        >
          Add School
        </b-button>
      </div>
      <center v-if="load">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </center>

      <div v-else>
        <b-table  :items="items.data" :fields="fields" striped responsive>
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" /> {{ row }}
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>
        <template #cell(expiry_date)="data">
          {{handleDateViewFormat(data.item.expiry_date)}}
          <!-- {{data.item.expiry_date}} -->
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-start align-items-center">

            <feather-icon icon="SendIcon" @click="mailAdmins(data.item.id)" class="mr-1 text-primary" size="20" v-b-tooltip.hover.top="'Mail Admins'" />

            <div v-if="hasPermission('Manage School')">
              <feather-icon v-if="!data.item.status" icon="UserXIcon" @click="switchStatus(data.item)" class="mr-1 text-dark" size="20" v-b-tooltip.hover.top="'Inactive school'" />
              <feather-icon v-else icon="UserCheckIcon"  @click="switchStatus(data.item)" class="mr-1 text-primary" size="20" v-b-tooltip.hover.top="'Active school'" />
            </div>
            <!-- <b-form-checkbox
            v-if="hasPermission('Manage School')"
              v-b-tooltip.hover.top="data.item.status ? 'Inactive' : 'Active'"
              @change="switchStatus(data.item)"
              :checked="data.item.status"
              name="check-button"
              switch
              inline
            >
            </b-form-checkbox> -->
            <b-link
            v-if="hasPermission('Manage School')"
              :tooltip="'none'"
              class="alert-link cutom-alert-link mr-1"
              :to="{ name: 'edit_school', params: { id: data.item.id} }"
            >
              <feather-icon :tooltip="'none'" icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Edit'"/>
              <!-- <feather-icon :tooltip="'none'" icon="EditIcon" size="18" v-b-tooltip.hover.top="'Edit'"/> -->
              <!-- <b-icon icon="arrow-up" ></b-icon> -->
            </b-link>
            <b-link
            v-if="hasPermission('Manage School')"
              class="alert-link cutom-alert-link"
              :to="{ name: 'users', query: { school_id: data.item.id} }"
            >
              <feather-icon icon="UsersIcon" size="18" v-b-tooltip.hover.top="'School Users'"/>
            </b-link> 
          </div>
        </template>
      </b-table>
      
      <b-pagination align="center"
        v-model="this.items.current_page"
        :total-rows="this.items.total"
        :per-page="this.items.per_page"
        @change="onPageChange"
      ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BLink,
  VBTooltip,
  BSpinner,
  BPagination,
  BFormInput,
  BootstrapVueIcons,
  IconsPlugin,
  BIconArrowUp,
  BIcon,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BLink,
    ToastificationContent,
    BSpinner,
    BPagination,
    vSelect,
    BFormInput,
    BootstrapVueIcons,
    IconsPlugin,
    BIconArrowUp,
    BIcon,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  watch: {
    status(newVal) {

    },
    "filter.status"(newVal) {
      this.$store.dispatch("schools/schools", { query: this.filter });
    },
    "filter.keyword"(newVal) {
      this.$store.dispatch("schools/schools", { query: this.filter });
    },
  },
  computed: {
    ...mapGetters({
      items: "schools/items",
      load: "schools/load",
    }),
  },
  data() {
    return {
    //   status: true,
      filter: {
        status: null,
        keyword:null
      },
      status_data:[
        {id:'1', name:'Active'},
        {id:'0', name:'Inactive'},
      ],
      record: null,
      fields: [
        "id",
        { key: "name", label: "Name" ,sortable: true },
        { key: "total_seats", label: "Total Seats" ,sortable: true},
        { key: "used_seats", label: "Used Seats" ,sortable: true},
        // { key: "create_date", label: "Create Date" },
        { key: "expiry_date", label: "Expiry Date" ,sortable: true },
        { key: "teachers_code", label: "Teachers Code" ,sortable: false},
        { key: "students_code", label: "Students Code" },
        "Actions",
      ],
    };
  },
  methods: {
    
    onPageChange(page) {
      this.$store.dispatch("schools/schools",{query: {page: page},} ).then((_) => {
      });

    },
    addSchool() {
      this.$router.push({ name: "add_school" });
    },
    
    mailAdmins(id){
      
      this.$store.dispatch( 'schools/mailSchool' , { id:id} ).then(res=>{
        this.$swal({
          icon: 'success',
          title: 'Sent successfully',
          showConfirmButton: true,
          confirmButtonColor: '#E84185',
          allowOutsideClick: true
        });
      }).catch(error => {
          if (error.response) {
            this.$swal({
              icon: 'error',
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: '#E84185',
              allowOutsideClick: true
            });
          }
      });
    },
    switchStatus(item){
      let _this = this
      this.$swal({
        icon: 'warning',
        title: `Are you sure?`,
        showCancelButton: true,
        confirmButtonText: 'Yes, I am sure!',
        cancelButtonText: 'No, cancel it!',
        confirmButtonColor: '#E84185',
      }).then(function(isConfirm) {
        if (isConfirm.isConfirmed) {
            _this.$store.dispatch('schools/inactiveSchool', item.id).then((_) =>{
              _this.refreshData();
            })
            //refreshData
        } else {
          // if(item.status == true){
          //   _this.model = true
          // }else{
          //   _this.model = false
          // }
        }
      });
    },
    refreshData(){
      this.$store.dispatch("schools/schools",{query: {page: this.items.current_page},} );
    },
  },
  mounted() {
    this.$store.dispatch("schools/schools", {query: {page: 1},}).then((_) => {
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
